/**
 * Copyright 2023 Illumio, Inc. All Rights Reserved.
 */

import intl from '@illumio-shared/utils/intl';

export function parseVulnerabilities(vulnerabilities) {
  let aggregatedValues;

  const summary =
    vulnerabilities.aggregated_detected_vulnerability_summary ?? vulnerabilities.detected_vulnerability_summary ?? {};
  const instances = (
    vulnerabilities.aggregated_detected_vulnerabilities ??
    vulnerabilities.workload_detected_vulnerabilities ??
    []
  ).reduce((result, vulnerability) => {
    const key = [vulnerability.port, vulnerability.proto].join(',');
    const severity = vulnerability.vulnerability.score / 10;
    const vulnerablePortExposure = vulnerability.hasOwnProperty('vulnerable_port_exposure')
      ? vulnerability.vulnerable_port_exposure
      : vulnerability.port_exposure;
    const wideExposure = vulnerability.vulnerable_port_wide_exposure || vulnerability.port_wide_exposure;
    let vulnerabilityExposureScore = null;

    if (!vulnerability.hasOwnProperty('vulnerability_exposure_score')) {
      vulnerabilityExposureScore =
        vulnerablePortExposure === null
          ? null
          : (Math.floor(Math.sqrt(vulnerablePortExposure) * (Math.pow(severity, 3) / 10)) / 10) *
            (vulnerability.num_workloads || 1);
    } else if (vulnerability.vulnerability_exposure_score !== null) {
      vulnerabilityExposureScore = vulnerability.vulnerability_exposure_score / 10;
    }

    const instance = {
      port: vulnerability.port,
      protocol: vulnerability.proto,
      severity,
      wideExposure,
      numWorkloads: vulnerability.num_workloads,
      vulnerablePortExposure,
      currentVulnerablePortExposure: vulnerability.current_enforcement_vulnerability_exposure?.vulnerable_port_exposure,
      visibilityVulnerablePortExposure:
        vulnerability.visibility_enforcement_vulnerability_exposure?.vulnerable_port_exposure,
      fullVulnerablePortExposure: vulnerability.full_enforcement_vulnerability_exposure?.vulnerable_port_exposure,
      selectiveVulnerablePortExposure:
        vulnerability.selective_enforcement_vulnerability_exposure?.vulnerable_port_exposure,
      vulnerabilityScore: severity * (vulnerability.num_workloads || 1),
      vulnerabilityExposureScore,
      currentVulnerabilityExposureScore: formatVulnerabilityExposureScore(
        vulnerability,
        'current_enforcement_vulnerability_exposure',
      ),
      visibilityVulnerabilityExposureScore: formatVulnerabilityExposureScore(
        vulnerability,
        'visibility_enforcement_vulnerability_exposure',
      ),
      fullVulnerabilityExposureScore: formatVulnerabilityExposureScore(
        vulnerability,
        'full_enforcement_vulnerability_exposure',
      ),
      selectiveVulnerabilityExposureScore: formatVulnerabilityExposureScore(
        vulnerability,
        'selective_enforcement_vulnerability_exposure',
      ),
      vulnerabilityComputationState: summary?.vulnerability_computation_state ?? null,
      details: vulnerability.vulnerability,
    };

    aggregatedValues = aggregateVulnerabilityValues(aggregatedValues, instance, summary, key);

    result[key] = (result[key] || []).concat([instance]);

    return result;
  }, {});

  return {aggregatedValues, instances};
}

export function formatVulnerabilityExposureScore(vulnerability, mode) {
  if (vulnerability.hasOwnProperty(mode) && vulnerability[mode]) {
    const exposureScore = vulnerability[mode].vulnerability_exposure_score;

    return exposureScore === null ? null : exposureScore / 10;
  }

  return null;
}

export function aggregateVulnerabilityValues(values, instance, summary) {
  const aggregatedValues = values || {
    maxSeverity: 0,
    maxExpSeverity: -1,
    wideExposure: {},
    vulnerablePortExposure: null,
    currentVulnerablePortExposure: null,
    visibilityVulnerablePortExposure: null,
    fullVulnerablePortExposure: null,
    selectiveVulnerablePortExposure: null,
    vulnerabilityScore: 0,
    vulnerabilityExposureScore: null,
    currentVulnerabilityExposureScore: null,
    visibilityVulnerabilityExposureScore: null,
    fullVulnerabilityExposureScore: null,
    selectiveVulnerabilityExposureScore: null,
    maxVulnerabilityExposure: -1,
    maxVulnerabilityExposureScore: -1,
    portValue: false,
    vulnerabilityComputationState: summary?.vulnerability_computation_state ?? null,
  };

  let {
    wideExposure,
    maxSeverity,
    maxExpSeverity,
    vulnerablePortExposure,
    currentVulnerablePortExposure,
    visibilityVulnerablePortExposure,
    fullVulnerablePortExposure,
    selectiveVulnerablePortExposure,
    vulnerabilityScore,
    vulnerabilityExposureScore,
    currentVulnerabilityExposureScore,
    visibilityVulnerabilityExposureScore,
    fullVulnerabilityExposureScore,
    selectiveVulnerabilityExposureScore,
    maxVulnerabilityExposure,
    maxVulnerabilityExposureScore,
    portValue,
    vulnerabilityComputationState,
  } = aggregatedValues;

  if (instance.wideExposure && (instance.wideExposure.any || instance.wideExposure.ip_list)) {
    maxExpSeverity = Math.max(
      maxExpSeverity,
      instance.hasOwnProperty('severity') ? instance.severity : instance.maxSeverity,
    );

    wideExposure = {
      any: wideExposure.any || instance.wideExposure.any,
      ip_list: wideExposure.ip_list || instance.wideExposure.ip_list,
    };
  }

  maxSeverity = Math.max(maxSeverity, instance.hasOwnProperty('severity') ? instance.severity : instance.maxSeverity);
  maxVulnerabilityExposure = Math.max(vulnerabilityExposureScore, instance.vulnerabilityExposureScore);
  maxVulnerabilityExposureScore = Math.max(maxVulnerabilityExposureScore, instance.currentVulnerabilityExposureScore);

  if (instance.vulnerablePortExposure) {
    maxExpSeverity = Math.max(
      maxExpSeverity,
      instance.hasOwnProperty('severity') ? instance.severity : instance.maxSeverity,
    );
  }

  // Aggregate the vulnerability and exposure scores
  if (instance.vulnerablePortExposure !== null && instance.vulnerablePortExposure !== intl('Common.NA')) {
    if (vulnerablePortExposure === null || vulnerablePortExposure === intl('Common.NA')) {
      vulnerablePortExposure = 0;
      vulnerabilityExposureScore = 0;
    }

    vulnerablePortExposure += instance.vulnerablePortExposure;
    vulnerabilityExposureScore += instance.vulnerabilityExposureScore;
  }

  // Aggregate the current vulnerability and exposure scores
  if (instance.currentVulnerablePortExposure !== null && instance.currentVulnerablePortExposure !== intl('Common.NA')) {
    if (currentVulnerablePortExposure === null || currentVulnerablePortExposure === intl('Common.NA')) {
      currentVulnerablePortExposure = 0;
      currentVulnerabilityExposureScore = 0;
    }

    currentVulnerablePortExposure += instance.currentVulnerablePortExposure;
    currentVulnerabilityExposureScore += instance.currentVulnerabilityExposureScore;
  }

  // Aggregate the full vulnerability and exposure scores
  if (instance.fullVulnerablePortExposure !== null && instance.fullVulnerablePortExposure !== intl('Common.NA')) {
    if (fullVulnerablePortExposure === null || fullVulnerablePortExposure === intl('Common.NA')) {
      fullVulnerablePortExposure = 0;
      fullVulnerabilityExposureScore = 0;
    }

    fullVulnerablePortExposure += instance.fullVulnerablePortExposure;
    fullVulnerabilityExposureScore += instance.fullVulnerabilityExposureScore;
  }

  // Aggregate the visbility only vulnerability and exposure scores
  if (
    instance.visibilityVulnerablePortExposure !== null &&
    instance.visibilityVulnerablePortExposure !== intl('Common.NA')
  ) {
    if (visibilityVulnerablePortExposure === null || visibilityVulnerablePortExposure === intl('Common.NA')) {
      visibilityVulnerablePortExposure = 0;
      visibilityVulnerabilityExposureScore = 0;
    }

    visibilityVulnerablePortExposure += instance.visibilityVulnerablePortExposure;
    visibilityVulnerabilityExposureScore += instance.visibilityVulnerabilityExposureScore;
  }

  // Aggregate the selective vulnerability and exposure scores
  if (
    instance.selectiveVulnerablePortExposure !== null &&
    instance.selectiveVulnerablePortExposure !== intl('Common.NA')
  ) {
    if (selectiveVulnerablePortExposure === null || selectiveVulnerablePortExposure === intl('Common.NA')) {
      selectiveVulnerablePortExposure = 0;
      selectiveVulnerabilityExposureScore = 0;
    }

    selectiveVulnerablePortExposure += instance.selectiveVulnerablePortExposure;
    selectiveVulnerabilityExposureScore += instance.selectiveVulnerabilityExposureScore;
  }

  if (instance.vulnerablePortExposure === intl('Common.NA') && vulnerablePortExposure === null) {
    vulnerablePortExposure = intl('Common.NA');
    vulnerabilityExposureScore = intl('Common.NA');
  }

  if (instance.currentVulnerablePortExposure === intl('Common.NA') && currentVulnerablePortExposure === null) {
    currentVulnerablePortExposure = intl('Common.NA');
    currentVulnerabilityExposureScore = intl('Common.NA');
  }

  if (instance.fullVulnerablePortExposure === intl('Common.NA') && fullVulnerablePortExposure === null) {
    fullVulnerablePortExposure = intl('Common.NA');
    fullVulnerabilityExposureScore = intl('Common.NA');
  }

  if (instance.visibilityVulnerablePortExposure === intl('Common.NA') && visibilityVulnerablePortExposure === null) {
    visibilityVulnerablePortExposure = intl('Common.NA');
    visibilityVulnerabilityExposureScore = intl('Common.NA');
  }

  if (instance.selectiveVulnerablePortExposure === intl('Common.NA') && selectiveVulnerablePortExposure === null) {
    selectiveVulnerablePortExposure = intl('Common.NA');
    selectiveVulnerabilityExposureScore = intl('Common.NA');
  }

  vulnerabilityScore += instance.vulnerabilityScore;

  portValue |= instance.port !== null;

  return {
    wideExposure,
    maxSeverity,
    maxExpSeverity,
    vulnerablePortExposure,
    currentVulnerablePortExposure,
    visibilityVulnerablePortExposure,
    fullVulnerablePortExposure,
    selectiveVulnerablePortExposure,
    vulnerabilityScore,
    vulnerabilityExposureScore,
    currentVulnerabilityExposureScore,
    visibilityVulnerabilityExposureScore,
    fullVulnerabilityExposureScore,
    selectiveVulnerabilityExposureScore,
    maxVulnerabilityExposure,
    maxVulnerabilityExposureScore,
    portValue,
    vulnerabilityComputationState,
  };
}

export function calculateComboLinksWithVulnerabilities(comboLinks, detectedVulnerabilities) {
  return Object.entries(comboLinks).reduce((result, [comboLinkKey, comboLink]) => {
    result[comboLinkKey] = {
      ...comboLink,
      vulnerabilities: {instances: []},
    };

    const vulnerabilities = detectedVulnerabilities[comboLink.data.id2];

    if (vulnerabilities) {
      const summary = {
        vulnerability_computation_state: vulnerabilities?.aggregatedValues?.vulnerabilityComputationState ?? null,
      };

      Object.values(result[comboLinkKey].services).forEach(service => {
        const serviceKey = [service.port, service.protocolNum].join(',');
        const instances = vulnerabilities.instances[serviceKey];

        if (instances) {
          instances.forEach(instance => {
            result[comboLinkKey].vulnerabilities.aggregatedValues = aggregateVulnerabilityValues(
              result[comboLinkKey].vulnerabilities.aggregatedValues,
              instance,
              summary,
            );
            result[comboLinkKey].vulnerabilities.instances = (
              result[comboLinkKey].vulnerabilities.instances || []
            ).concat([instance]);
          });
        }
      });
    }

    return result;
  }, {});
}
