/**
 * Copyright 2023 Illumio, Inc. All Rights Reserved.
 */
import {
  formatCVEIDs,
  formatVulnerabilityScore,
} from 'containers/IlluminationMap/Panels/Vulnerabilities/MapPanelVulnerabilitiesUtils';
import {
  formatPortExposure,
  formatProProtocol,
  formatVulnerability,
} from 'containers/Workload/Item/Vulnerabilities/WorkloadVulnerabilitiesUtils';
import intl from '@illumio-shared/utils/intl';
import styles from 'containers/Workload/Item/Vulnerabilities/WorkloadVulnerabilities.css';
import {sortVulnerabilityPortExposure, sortVulnerabilities} from 'components/Vulnerability/VulnerabilityUtils';

const vulnerabilitiesTemplates = [
  [
    {columns: ['potentialVEScore'], size: 'minmax(80px, auto)'},
    {columns: ['currentVEScore'], size: 'minmax(60px, auto)'},
    {columns: ['potentialExposure'], size: 'minmax(80px, auto)'},
    {columns: ['currentExposure'], size: 'minmax(80px, auto)'},
    {columns: ['workloads'], size: 'minmax(80px, auto)'},
    {columns: ['port'], size: 'minmax(80px, auto)'},
    {columns: ['name'], size: 'minmax(100px, auto)'},
    {columns: ['cveIds'], size: 'minmax(100px, auto)'},
    {columns: ['vulnerabilityStandardScore'], size: 'minmax(80px, auto)'},
  ],
  {
    maxWidth: 640,
    template() {
      return [
        {
          columns: ['potentialVEScore', 'currentVEScore', 'potentialExposure', 'currentExposure'],
          size: 'minmax(200px, auto)',
        },
        {columns: ['workloads', 'port', 'name', 'cveIds', 'vulnerabilityStandardScore'], size: 'minmax(200px, auto)'},
      ];
    },
  },
];

export const gridConfig = (isFullEnforcement, totalVEScore) => ({
  id: 'vulnerabilities',
  sort: '-currentVEScore',
  capacities: [25, 50, 100, 250, 500],
  capacity: 50,
  maxPage: Number.MAX_SAFE_INTEGER,
  showColumns: true,
  showCapacity: true,
  showPagination: true,
  columns: {
    potentialVEScore: {
      header: isFullEnforcement
        ? totalVEScore === null
          ? intl('Vulnerability.FullEnforcementVEScore')
          : intl(
              'Vulnerability.FullEnforcementVEScoreWithTotal',
              {total: totalVEScore.full, className: styles.totalVEScore},
              {html: true},
            )
        : totalVEScore === null
        ? intl('Vulnerability.VisibilityOnlyVEScore')
        : intl(
            'Vulnerability.VisibilityOnlyVEScoreWithTotal',
            {total: totalVEScore.visibility_only, className: styles.totalVEScore},
            {html: true},
          ),
      value: ({row}) =>
        isFullEnforcement ? row.fullVulnerabilityExposureScore : row.visibilityVulnerabilityExposureScore,
      format: ({row, value}) =>
        formatVulnerability(
          row,
          value,
          isFullEnforcement ? row.fullVulnerablePortExposure : row.visibilityVulnerablePortExposure,
        ),
      sort: ({row}) => {
        const mode = isFullEnforcement ? 'fullVulnerabilityExposureScore' : 'visibilityVulnerabilityExposureScore';
        const vulnerabilityExposureScore = row[mode];

        return {...row, vulnerabilityExposureScore};
      },
      sortFunction: sortVulnerabilities,
    },
    currentVEScore: {
      header:
        totalVEScore === null
          ? intl('Vulnerability.CurrentVEScore')
          : intl(
              'Vulnerability.CurrentVEScoreWithTotal',
              {total: totalVEScore.current, className: styles.totalVEScore},
              {html: true},
            ),
      value: ({row}) => row.currentVulnerabilityExposureScore,
      format: ({row, value}) => formatVulnerability(row, value, row.currentVulnerablePortExposure),
      sort: ({row}) => ({...row, vulnerabilityExposureScore: row.currentVulnerabilityExposureScore}),
      sortFunction: sortVulnerabilities,
    },
    potentialExposure: {
      header: isFullEnforcement
        ? intl('Vulnerability.FullEnforcementExposure')
        : intl('Vulnerability.VisibilityOnlyExposure'),
      value: ({row}) => (isFullEnforcement ? row.fullVulnerablePortExposure : row.visibilityVulnerablePortExposure),
      format: ({row, value}) => formatPortExposure(row, value),
      sort: ({row}) => {
        const mode = isFullEnforcement ? 'fullVulnerablePortExposure' : 'visibilityVulnerablePortExposure';
        const portExposure = row[mode];

        return {...row, portExposure};
      },
      sortFunction: sortVulnerabilityPortExposure,
    },
    currentExposure: {
      header: intl('Vulnerability.CurrentExposure'),
      value: ({row}) => formatPortExposure(row, row.currentVulnerablePortExposure),
      sort: ({row}) => ({...row, portExposure: row.currentVulnerablePortExposure}),
      sortFunction: sortVulnerabilityPortExposure,
    },
    workloads: {
      header: intl('Common.Workloads'),
      value: ({row}) => row.numWorkloads,
    },
    port: {
      header: intl('Port.Protocol'),
      value: ({row}) => formatProProtocol(row),
    },
    name: {
      header: intl('Common.Name'),
      value: ({row}) => row.details.name,
    },
    cveIds: {
      header: intl('Vulnerability.CVEIds'),
      value: ({row}) => formatCVEIDs(row),
    },
    vulnerabilityStandardScore: {
      header: intl('Vulnerability.VulnerabilityStandardScore'),
      value: ({row}) => row.severity,
      format: ({value}) => formatVulnerabilityScore(value),
    },
  },
  templates: vulnerabilitiesTemplates,
});
