/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
import {portUtils} from '@illumio-shared/utils';
import {Vulnerability} from 'components';
import {getPolicyState} from '../../WorkloadUtils';
import intl from '@illumio-shared/utils/intl';
import styles from './WorkloadVulnerabilities.css';
import {roundNumber} from 'components/Vulnerability/VulnerabilityUtils';

export const formatProProtocol = row => {
  if (!row.protocol) {
    return null;
  }

  const protocol = portUtils.lookupProtocol(row.protocol);

  return row.port ? `${row.port} ${protocol}` : row.protocol ? protocol : null;
};

export const formatVulnerability = (row, value, portExposure) => {
  if (!row || (row.hasOwnProperty('num_vulnerabilities') && !row.num_vulnerabilities)) {
    return;
  }

  let exposureApplicable = row.exposureApplicable;

  if (row) {
    const config = row.agent && row.agent.config;
    const policyState = getPolicyState(config?.mode, config?.log_traffic);

    exposureApplicable = policyState && policyState !== 'idle';
  }

  let maxSeverity = row.maxSeverity;
  const internetExposure = row.vulnerable_port_wide_exposure || row.wideExposure;

  if (row.hasOwnProperty('max_vulnerability_score')) {
    maxSeverity = row.max_vulnerability_score / 10;
  } else if (row.hasOwnProperty('severity')) {
    maxSeverity = row.severity;
  }

  return (
    <Vulnerability
      internetExposure={internetExposure?.any || internetExposure?.ip_list}
      severityScore={maxSeverity}
      vulnerabilityExposureScore={value}
      portExposure={portExposure}
      exposureApplicable={exposureApplicable}
      vulnerabilityComputationState={row.vulnerabilityComputationState}
    />
  );
};

export const formatPortExposure = (row, value) => {
  if (row.vulnerabilityComputationState === 'not_applicable') {
    return intl('Common.NA');
  }

  return roundNumber(value);
};

export const formatTraffic = value => {
  switch (value) {
    case 'allowed':
      return <span className={styles.allowed}>{intl('Common.Allowed')}</span>;
    case 'potentially_blocked':
      return <span className={styles.potentiallyBlocked}>{intl('Common.PotentiallyBlocked')}</span>;
    case 'blocked':
      return <span className={styles.blocked}>{intl('Common.Blocked')}</span>;
    case 'unknown':
      return <span className={styles.unknown}>{intl('Common.Unknown')}</span>;
    case 'none':
    default:
      return <span>{intl('Common.None')}</span>;
  }
};

/**
 * Resolves policy decisions for multiple links based on priority.
 * @param decisions {[key: string]: boolean} - map of policy decisions to resolve
 * @returns {string} unknown, allowed, potentially_blocked, blocked, or none
 */
const getPriorityPolicy = decisions =>
  ['allowed', 'unknown', 'potentially_blocked', 'blocked'].find(decision => decisions[decision]) || 'none';

/**
 * Aggregates explorer traffic data by port/protocol, and extracts the data needed
 * by workload vulnerabilities table.
 * @param links
 * @returns {*} simplified links aggregated by port/protocol
 */
export const aggregateTrafficData = links => {
  return (links ?? [])
    .filter(link => link.flow_direction !== 'outbound')
    .reduce((aggregated, link) => {
      const key = [link.service.port, link.service.proto].join(',');

      aggregated[key] ??= {policyDecision: link.policy_decision};
      aggregated[key].policyDecision = getPriorityPolicy({
        [aggregated[key].policyDecision]: true,
        [link.policy_decision]: true,
      });

      return aggregated;
    }, {});
};

/**
 * Generates the payload for an explorer query with a workload in the destination.include field.
 * This is used to generate a query that will fetch the links with a particular workload as the destination.
 * @param workloadHref
 * @returns Object
 */
export const getVulnerabilitiesExplorerQuery = workloadHref => {
  const endDate = new Date();
  const startDate = intl.utils.subtractTime(endDate, 'd', 30);

  return {
    boundary_decisions: [],
    policy_decisions: [],
    sources: {
      include: [[]],
      exclude: [],
    },
    destinations: {
      include: [[{workload: {href: workloadHref}}]],
      exclude: [],
    },
    services: {
      include: [],
      exclude: [],
    },
    sources_destinations_query_op: 'and',
    exclude_workloads_from_ip_list_query: true,
    query_name: `Workload Destination: ${workloadHref}`,
    start_date: startDate.toISOString(),
    end_date: endDate.toISOString(),
    max_results: 10_000,
  };
};
