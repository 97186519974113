/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from '@illumio-shared/utils/intl';
import {createSelector} from 'reselect';
import {DateTimeCustomPicker, StatusIcon, Vulnerability} from 'components';
import * as GridUtils from 'components/Grid/GridUtils';
import {getVulnerabilityValues, getWorkloadName, getWorkloadStatus, getWorkloadSyncElement} from '../WorkloadUtils';
import {sortVulnerabilities} from 'components/Vulnerability/VulnerabilityUtils';
import {getWorkloadStatusIntl} from '@illumio-shared/utils/intl/dynamic';
import {enforcementModeView, visibilityLevelView} from 'containers/EnforcementBoundaries/EnforcementBoundariesUtils';
import {ransomwareExposureMap} from 'containers/RansomwareDashboard/RansomwareMetrics/MetricsUtils';
import {getRouteName, getRouteParams, isRansomwareReadinessEnabled} from 'containers/App/AppState';
import {getClusters, getLocalFQDN} from 'containers/Health/HealthState';
import {getDisplayNames, getTypeInitialRegExp} from 'containers/Label/LabelSettings/LabelSettingState';
import {populateFacetCategory, populateLabelsCategories} from 'containers/Selector/GridFilter/GridFilterUtils';
import {getWorkloadFilter as getVirtualServerWorkloadFilter} from 'containers/VirtualServer/Item/Members/VirtualServerMembersState';
import {getAppGroups} from 'containers/AppGroups/AppGroupState';

export const resourceType = 'workloads';
const fromPresets = [
  {name: 'anytime', label: 'Anytime', value: null},
  {name: 'custom', label: 'Custom Time', value: 'custom'},
];

const toPresets = [
  {name: 'now', label: 'Now', value: new Date()},
  {name: 'custom', label: 'Custom Time', value: 'custom'},
];

export const policyHealthOptions = createSelector([], () => ({
  error: {id: 'error', value: intl('Common.Error')},
  warning: {id: 'warning', value: intl('Common.Warning')},
  active: {
    id: 'active',
    value: intl('Common.Active'),
  },
  suspended: {id: 'suspended', value: intl('Workloads.Status.Suspended')},
  staged: {id: 'staged', value: intl('Common.Staged')},
}));

const populateDateTimePickerResources = id => ({
  [id]: {
    type: 'container',
    container: DateTimeCustomPicker,
    containerProps: {
      noBorder: true,
      fromPresets,
      toPresets,
      getContainerProps: ({onDone, setCategories}) => ({
        // overriding default go back functionality
        onClose: () => {
          setCategories({combined: {active: true}});
        },
        onSave: onDone,
      }),
    },
    enableFocusLock: true,
  },
});

export const categories = createSelector(
  [
    getRouteName,
    getRouteParams,
    policyHealthOptions,
    getTypeInitialRegExp,
    getDisplayNames,
    getVirtualServerWorkloadFilter,
    getClusters,
    getLocalFQDN,
    isRansomwareReadinessEnabled,
    getAppGroups,
  ],
  (
    routeName,
    routeParams,
    policyHealthOptions,
    labelTypeInitialRegExp,
    labelTypesNameObj,
    virtualServerWorkloadFilter,
    clusters,
    localFQDN,
    isRansomwareReadinessEnabled,
    appGroups,
  ) => {
    const query = {};
    const isInAppGroup = routeName === 'app.appGroups.detail.members';

    if (isInAppGroup) {
      const selectedAppGroup = appGroups.find(appGroup => appGroup.href === routeParams.id);
      const selectedLabels = selectedAppGroup.labels.map(item => ({label: {href: item.href}}));

      query.selected_scope = JSON.stringify(selectedLabels);
    }

    if (routeName.includes('app.containerClusters')) {
      query.container_cluster_id = routeParams.id;
    }

    const facetCategoryArgs = {
      resourceType,
      ...(_.isEmpty(query) ? {} : {query}),
    };

    const excludeLabelKeys =
      routeName.includes('virtualServers.item.members') &&
      virtualServerWorkloadFilter?.labelsAndLabelGroups.map(({key}) => key);

    return [
      populateFacetCategory({
        ...facetCategoryArgs,
        id: 'name',
        name: intl('Common.Name'),
      }),
      ...(isInAppGroup
        ? []
        : populateLabelsCategories({
            resourceType,
            hasNoLabels: true,
            labelTypesNameObj,
            labelTypeInitialRegExp,
            query: {
              exclude_keys: excludeLabelKeys ? JSON.stringify(excludeLabelKeys) : undefined,
            },
          })),
      populateFacetCategory({
        ...facetCategoryArgs,
        id: 'ip_address',
        name: intl('Common.IPAddress'),
      }),
      populateFacetCategory({
        ...facetCategoryArgs,
        id: 'description',
        name: intl('Common.Description'),
      }),
      populateFacetCategory({
        ...facetCategoryArgs,
        id: 'os_id',
        name: intl('Common.OS'),
      }),
      populateFacetCategory({
        ...facetCategoryArgs,
        id: 'hostname',
        name: intl('Common.Hostname'),
      }),

      {
        id: 'policy_health',
        name: intl('Workloads.PolicySync'),
        resources: {
          policy_health: {
            statics: Object.values(policyHealthOptions),
          },
        },
      },
      {
        id: 'enforcement_mode',
        name: intl('Common.Enforcement'),
        resources: {
          enforcement_mode: {
            statics: [
              {id: 'full', value: intl('Workloads.Full')},
              {id: 'selective', value: intl('EnforcementBoundaries.SelectiveEnforcement')},
              {id: 'visibility_only', value: intl('Common.VisibilityOnly')},
              {id: 'idle', value: intl('Common.Idle')},
            ],
            optionProps: {
              allowMultipleSelection: true,
            },
            selectedProps: {
              valueJoiner: 'or',
              resourceJoiner: 'and',
            },
          },
        },
      },
      ...(isRansomwareReadinessEnabled
        ? [
            {
              id: 'risk_summary.ransomware.workload_exposure_severity',
              name: intl('RansomwareDashboard.RansomwareExposure'),
              resources: {
                'risk_summary.ransomware.workload_exposure_severity': {
                  statics: [
                    {id: 'critical', value: intl('Common.Critical')},
                    {id: 'high', value: intl('Common.High')},
                    {id: 'low', value: intl('Common.Low')},
                    {id: 'medium', value: intl('Common.Medium')},
                    {id: 'fully_protected', value: intl('Common.Protected')},
                  ],
                },
              },
            },
          ]
        : []),
      {
        id: 'managed',
        name: intl('Common.Connectivity'),
        resources: {
          managed: {
            statics: [
              intl('Common.Online'),
              intl('Workloads.Status.Offline'),
              ...(routeName.startsWith('app.virtualServers') ? [] : [intl('Common.Unmanaged')]),
            ],
          },
        },
      },
      {
        id: 'security_policy_applied_at',
        name: intl('Workloads.PolicyLastApplied'),
        resources: populateDateTimePickerResources('security_policy_applied_at'),
      },
      {
        id: 'security_policy_received_at',
        name: intl('Workloads.PolicyLastReceived'),
        resources: populateDateTimePickerResources('security_policy_received_at'),
      },
      {
        id: 'security_policy_update_mode',
        name: intl('Policy.UpdateMode'),
        resources: {
          security_policy_update_mode: {
            statics: [
              {value: intl('Workloads.StaticWorkloads'), id: 'static'},
              {value: intl('Workloads.AdaptiveWorkloads'), id: 'adaptive'},
            ],
          },
        },
      },
      ...(clusters.length > 1
        ? [
            {
              id: 'agent.active_pce_fqdn',
              name: intl('Common.PCE'),
              hidden: true,
              resources: {
                'agent.active_pce_fqdn': {
                  statics: clusters.map(cluster => ({
                    id: cluster.fqdn,
                    value: cluster.fqdn === localFQDN ? intl('Workloads.ThisPCE', {fqdn: cluster.fqdn}) : cluster.fqdn,
                  })),
                },
              },
            },
          ]
        : []),
    ];
  },
);

export const clickableVEColumn = GridUtils.clickableColumn({
  format: ({row, value, clickableRef}) =>
    value ? <Vulnerability {...value} href={row.data.href} ref={clickableRef} /> : null,
  sort: ({value}) => value,
  sortFunction: sortVulnerabilities,
});

const defaultTemplates = [
  [
    {columns: ['checkboxes'], size: 'max-content', sizeOld: '30px'},
    {columns: ['status'], size: 'max-content', sizeOld: '40px'},
    {columns: ['potentialVEScore'], size: 'minmax(125px, 135px)'},
    {columns: ['currentVEScore'], size: 'minmax(100px, 110px)'},
    {columns: ['enforcement'], size: 'max-content'},
    {columns: ['visibility'], size: 'max-content'},
    {columns: ['sync'], size: 'minmax(max-content, auto)', sizeOld: '110px'},
    {columns: ['ransomwareExposure'], size: 'minmax(125px, auto)'},
    {columns: ['protectionCoverageScore'], size: 'minmax(125px, 135px)'},
    {columns: ['name'], size: 'minmax(150px, auto)'},
    {columns: ['hostname'], size: 'minmax(150px, auto)'},
    {columns: ['labels'], size: 'minmax(450px, auto)'},
    {columns: ['paired'], size: 'minmax(170px, auto)'},
    {columns: ['last'], size: 'minmax(170px, auto)'},
  ],
  {
    maxWidth: 1680,
    template(columns) {
      if (GridUtils.hasOptionalColumns(columns)) {
        //all column breakpoint
        return [
          {columns: ['checkboxes'], size: 'max-content', sizeOld: '30px'},
          {columns: ['status'], size: 'max-content', sizeOld: '35px'},
          {columns: ['potentialVEScore'], size: 'max-content', sizeOld: '115px'},
          {columns: ['currentVEScore'], size: 'max-content', sizeOld: '115px'},
          {columns: ['enforcement'], size: 'max-content', sizeOld: '115px'},
          {columns: ['visibility'], size: 'max-content', sizeOld: '115px'},
          {columns: ['sync'], size: 'minmax(max-content, auto)', sizeOld: '110px'},
          {columns: ['ransomwareExposure'], size: 'max-content', sizeOld: '115px'},
          {columns: ['protectionCoverageScore'], size: 'max-content', sizeOld: '115px'},
          {columns: ['name'], size: 'minmax(100px, auto)'},
          {columns: ['hostname'], size: 'minmax(100px, auto)'},
          {columns: ['labels'], size: 'minmax(400px, auto)'},
          {columns: ['paired'], size: 'minmax(120px, auto)'},
          {columns: ['last'], size: 'minmax(120px, auto)'},
        ];
      }

      return [
        {columns: ['checkboxes'], size: 'max-content'},
        {columns: ['status'], size: 'max-content'},
        {columns: ['potentialVEScore'], size: 'max-content', sizeOld: '115px'},
        {columns: ['currentVEScore'], size: 'max-content'},
        {columns: ['enforcement'], size: 'max-content'},
        {columns: ['visibility'], size: 'max-content'},
        {columns: ['sync'], size: 'minmax(max-content, auto)'},
        {columns: ['ransomwareExposure'], size: 'max-content'},
        {columns: ['protectionCoverageScore'], size: 'max-content'},
        {columns: ['name'], size: 'minmax(100px, auto)'},
        {columns: ['hostname'], size: 'minmax(100px, auto)'},
        {columns: ['labels'], size: 'minmax(200px, auto)'},
        {columns: ['last'], size: 'minmax(120px, auto)'},
        {columns: ['paired'], size: 'minmax(120px, auto)'},
      ];
    },
  },
  {
    maxWidth: 1440,
    template(columns) {
      if (GridUtils.hasOptionalColumns(columns)) {
        //all column breakpoint
        return [
          {columns: ['checkboxes'], size: 'max-content'},
          {
            columns: ['status', 'potentialVEScore', 'currentVEScore'],
            size: 'minmax(max-content, auto)',
          },
          {
            columns: ['enforcement', 'visibility'],
            size: 'minmax(120px, auto)',
          },
          {
            columns: ['name', 'hostname', 'sync', 'ransomwareExposure', 'protectionCoverageScore'],
            size: 'minmax(120px, auto)',
          },
          {columns: ['labels'], size: 'minmax(110px, auto)'},
          {columns: ['paired', 'last'], size: 'minmax(120px, auto)'},
        ];
      }

      return [
        {columns: ['checkboxes'], size: 'max-content', sizeOld: '35px'},
        {
          columns: ['status', 'potentialVEScore', 'currentVEScore'],
          size: 'minmax(max-content, auto)',
          sizeOld: '115px',
        },
        {
          columns: ['enforcement', 'visibility'],
          size: 'minmax(120px, auto)',
        },
        {
          columns: ['name', 'hostname', 'sync', 'ransomwareExposure', 'protectionCoverageScore'],
          size: 'minmax(120px, auto)',
        },
        {columns: ['labels'], size: 'minmax(110px, auto)'},
        {columns: ['paired', 'last'], size: 'minmax(120px, auto)'},
      ];
    },
  },
  {
    maxWidth: 1024,
    template(columns) {
      if (GridUtils.hasOptionalColumns(columns)) {
        //all column breakpoint
        return [
          {columns: ['checkboxes'], size: 'max-content', sizeOld: '35px'},
          {
            columns: [
              'status',
              'potentialVEScore',
              'currentVEScore',
              'sync',
              'ransomwareExposure',
              'protectionCoverageScore',
            ],
            size: 'minmax(max-content, auto)',
            sizeOld: '115px',
          },
          {
            columns: ['enforcement', 'visibility'],
            size: 'minmax(120px, auto)',
          },
          {columns: ['name', 'hostname', 'paired', 'last'], size: 'minmax(140px, auto)'},
          {columns: ['labels'], size: 'minmax(120px, auto)'},
        ];
      }

      return [
        {columns: ['checkboxes'], size: 'max-content', sizeOld: '35px'},
        {
          columns: [
            'status',
            'potentialVEScore',
            'currentVEScore',
            'sync',
            'ransomwareExposure',
            'protectionCoverageScore',
          ],
          size: 'minmax(max-content, auto)',
          sizeOld: '115px',
        },
        {
          columns: ['enforcement', 'visibility'],
          size: 'minmax(120px, auto)',
        },
        {columns: ['name', 'hostname', 'paired', 'last'], size: 'minmax(140px, auto)'},
        {columns: ['labels'], size: 'minmax(120px, auto)'},
      ];
    },
  },
  {
    maxWidth: 640,
    template(columns) {
      if (GridUtils.hasOptionalColumns(columns)) {
        //all column breakpoint
        return [
          {columns: ['checkboxes'], size: 'max-content', sizeOld: '30px'},
          {
            columns: ['status', 'potentialVEScore', 'currentVEScore', 'sync'],
            size: 'minmax(min-content, max-content)',
            sizeOld: '115px',
          },
          {
            columns: [
              'name',
              'hostname',
              'paired',
              'last',
              'enforcement',
              'ransomwareExposure',
              'protectionCoverageScore',
              'visibility',
            ],
            size: 'minmax(150px, auto)',
          },
          {columns: ['labels'], size: 'minmax(150px, auto)' /*, extraClass: styles.labels*/},
        ];
      }

      return [
        {columns: ['checkboxes'], size: 'max-content', sizeOld: '30px'},
        {
          columns: ['status', 'potentialVEScore', 'currentVEScore', 'sync'],
          size: 'minmax(min-content, max-content)',
          sizeOld: '115px',
        },
        {
          columns: [
            'name',
            'hostname',
            'paired',
            'last',
            'enforcement',
            'ransomwareExposure',
            'protectionCoverageScore',
            'visibility',
          ],
          size: 'minmax(150px, auto)',
        },
        {columns: ['labels'], size: 'minmax(150px, auto)' /*, extraClass: styles.labels*/},
      ];
    },
  },
  {
    maxWidth: 480,
    template(columns) {
      if (GridUtils.hasOptionalColumns(columns)) {
        //all column breakpoint
        return [
          {columns: ['checkboxes'], size: 'min-content', sizeOld: '30px'},
          {
            columns: [
              'status',
              'potentialVEScore',
              'currentVEScore',
              'name',
              'hostname',
              'sync',
              'paired',
              'last',
              'enforcement',
              'ransomwareExposure',
              'protectionCoverageScore',
              'visibility',
            ],
            size: 'minmax(140px, auto)',
          },
          {columns: ['labels'], size: 'minmax(140px, auto)'},
        ];
      }

      return [
        {columns: ['checkboxes'], size: 'min-content', sizeOld: '30px'},
        {
          columns: [
            'status',
            'potentialVEScore',
            'currentVEScore',
            'name',
            'hostname',
            'sync',
            'paired',
            'last',
            'enforcement',
            'ransomwareExposure',
            'protectionCoverageScore',
            'visibility',
          ],
          size: 'minmax(140px, auto)',
        },
        {columns: ['labels'], size: 'minmax(140px, auto)'},
      ];
    },
  },
];

/**
 [{
  id: string,
  header: string,

  key: string | Function,
  value: string | Function,
  format: Function,
  sort: Function, // Getter for sorting value
  sortFunction: Function, // Custom sort function
  sortable: [true]boolean,
  reactsToSelection: boolean, // If cell should be rerendered on row checkbox change
  isDate: boolean,
}];
 */

export const gridSettings = createSelector(
  [options => options],
  ({labelsColumn, isRansomwareReadinessEnabled, showPotentialVEScore, isFullEnforcement}) => {
    return {
      id: 'workloadlist',
      sort: 'name',
      capacities: [25, 50, 100, 250, 500],
      capacity: 50,
      maxPage: Number.MAX_SAFE_INTEGER,
      showColumns: true,
      showCapacity: true,
      showPagination: true,
      columns: {
        checkboxes: {},
        status: {
          header: intl('Common.Connectivity'),
          value: ({row}) =>
            row.unmanaged && row.data.agent?.config?.mode === 'idle' ? null : getWorkloadStatus(row.data),
          format: ({value}) =>
            value && (
              <>
                <StatusIcon
                  status={value}
                  title={`${intl('Workloads.VENConnectivity')}: ${getWorkloadStatusIntl(value)}`}
                />
                &nbsp;
                {getWorkloadStatusIntl(value)}
              </>
            ),
        },
        potentialVEScore: {
          ...clickableVEColumn,
          header: isFullEnforcement
            ? intl('Vulnerability.FullEnforcementVEScore')
            : intl('Vulnerability.VisibilityOnlyVEScore'),
          defaultOrder: 'desc',
          value: ({row}) =>
            !row.unmanaged &&
            getVulnerabilityValues(
              row.data,
              isFullEnforcement
                ? 'full_enforcement_vulnerability_exposure'
                : 'visibility_enforcement_vulnerability_exposure',
            ),
          disabled: !showPotentialVEScore,
        },
        currentVEScore: {
          ...clickableVEColumn,
          header: intl('Vulnerability.CurrentVEScore'),
          defaultOrder: 'desc',
          value: ({row}) =>
            !row.unmanaged && getVulnerabilityValues(row.data, 'current_enforcement_vulnerability_exposure'),
        },
        enforcement: {
          header: intl('Common.Enforcement'),
          value: ({row}) => (row.unmanaged ? null : enforcementModeView()[row.data.enforcement_mode]?.name),
        },
        ransomwareExposure: {
          header: intl('RansomwareDashboard.RansomwareExposure'),
          value: ({row}) => ransomwareExposureMap[row.data.risk_summary?.ransomware?.workload_exposure_severity]?.name,
          disabled: !isRansomwareReadinessEnabled,
        },
        protectionCoverageScore: {
          header: intl('RansomwareDashboard.ProtectionCoverageScore'),
          value: ({row}) => {
            return row.data.risk_summary?.ransomware?.ransomware_protection_percent;
          },
          format: ({value}) => {
            return value === undefined ? '' : `${Math.floor(value)}%`; // return percent display formatting
          },
          disabled: !isRansomwareReadinessEnabled,
        },
        visibility: {
          header: intl('Common.Visibility'),
          value: ({row}) =>
            row.unmanaged
              ? null
              : row.data.enforcement_mode === 'idle'
              ? visibilityLevelView()[row.data.enforcement_mode].name
              : visibilityLevelView()[row.data.visibility_level]?.name,
        },
        sync: {
          header: intl('Workloads.PolicySync'),
          value: ({row}) => (row.healthStatus && !row.unmanaged ? row.healthStatus.label : null),
          format: ({value, row}) => getWorkloadSyncElement(row.healthStatus, value),
        },
        name: {
          linky: true,
          header: intl('Common.Name'),
          value: ({row}) => getWorkloadName(row.data),
          required: true,
        },
        hostname: {
          linky: true,
          header: intl('Common.Hostname'),
          value: ({row}) => (row.unmanaged ? null : row.data?.hostname),
          optional: true,
        },
        labels: labelsColumn,
        last: {
          isDate: true,
          header: intl('Workloads.LastAppliedPolicy'),
          value: ({row}) => (row.unmanaged ? null : row.data.agent.status.security_policy_refresh_at),
        },
        paired: {
          isDate: true,
          header: intl('Common.Paired'),
          value: ({row}) => (row.unmanaged ? null : row.data.agent.status.managed_since),
          optional: true,
          sortable: !__ANTMAN__,
        },
        ...(__ANTMAN__
          ? {
              created_at: {
                value: ({row}) => row.data.created_at,
                manager: false,
              },
            }
          : {}),
      },
      templates: defaultTemplates,
    };
  },
);
