/**
 * Copyright 2023 Illumio, Inc. All Rights Reserved.
 */
import createCachedSelector from 're-reselect';
import {createSelector} from 'reselect';
import {getGridSelector} from 'components/Grid/GridSelectors';
import {getVulnerabilitiesGridConfig} from 'containers/IlluminationMap/Panels/Vulnerabilities/MapPanelVulnerabilitiesConfig';
import {webStorageUtils} from '@illumio-shared/utils';

export default {
  mapVulnerabilitiesEnforcementToggle(
    state = webStorageUtils.getItem('mapVulnerabilitiesEnforcementToggle') ?? true,
    action,
  ) {
    switch (action.type) {
      case 'MAP_VULNERABILITIES_TOGGLE_ENFORCEMENT':
        webStorageUtils.setItem('mapVulnerabilitiesEnforcementToggle', action.data);

        return action.data;
      default:
        return state;
    }
  },
};

export const getMapVulnerabilitiesEnforcementToggle = state => state.map?.panel?.mapVulnerabilitiesEnforcementToggle;

export const getVulnerabilitiesGridSettings = createCachedSelector(
  [getMapVulnerabilitiesEnforcementToggle, (_, props) => props],
  (isFullEnforcement, props) => {
    return getVulnerabilitiesGridConfig({...props, isFullEnforcement});
  },
)((state, props) => props?.gridId ?? getVulnerabilitiesGridConfig(state).id);

export const getVulnerabilitiesRows = createSelector([(_, props) => props], props => {
  return (props?.getVulnerabilitiesRows?.() ?? []).map((row, index) => ({...row, key: index}));
});

export const getVulnerabilitiesGrid = (state, props) =>
  getGridSelector(state, {
    settings: state => getVulnerabilitiesGridSettings(state, props),
    rows: state => getVulnerabilitiesRows(state, props),
  });

export const getVulnerabilitiesList = createSelector(
  [getVulnerabilitiesGrid, getMapVulnerabilitiesEnforcementToggle],
  (grid, isFullEnforcement) => {
    return {
      grid,
      isFullEnforcement,
    };
  },
);
