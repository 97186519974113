/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
import {createSelector} from 'reselect';
import {getGridSelector} from 'components/Grid/GridSelectors';
import {gridSettings} from './WorkloadVulnerabilitiesConfig';
import {getWorkloadInstance} from '../WorkloadItemState';
import {roundNumber} from 'components/Vulnerability/VulnerabilityUtils';
import * as webStorageUtils from '../../../../../utils/webStorage';
import {areVulnerabilitiesEnabled} from 'containers/App/AppState';
import {isUserWorkloadManager} from 'containers/User/UserState';

export default {
  vulnerabilities(state = {}, action) {
    switch (action.type) {
      case 'WORKLOAD_VULNERABILITIES':
        state[action.key] = action.data;

        return {...state};
      default:
        return state;
    }
  },

  traffic(state = {}, action) {
    switch (action.type) {
      case 'WORKLOAD_VULNERABILITIES_GET_TRAFFIC':
        return action.data;
      default:
        return state;
    }
  },
  workloadVulnerabilitiesEnforcementToggle(
    state = webStorageUtils.getItem('workloadVulnerabilitiesEnforcementToggle') ?? true,
    action,
  ) {
    switch (action.type) {
      case 'WORKLOAD_VULNERABILITIES_TOGGLE_ENFORCEMENT':
        webStorageUtils.setItem('workloadVulnerabilitiesEnforcementToggle', action.data);

        return action.data;
      default:
        return state;
    }
  },
};

export const getVulnerabilities = state => state.workload.vulnerabilities;
export const getTraffic = state => state.workload.traffic;
export const getVulnerabilitiesEnforcementToggle = state => state.workload.workloadVulnerabilitiesEnforcementToggle;

export const getVulnerabilitiesData = createSelector(
  [getVulnerabilities, getWorkloadInstance, getTraffic],
  (vulnerabilities, workload, traffic) => {
    return vulnerabilities[workload.href]
      ? Object.values(vulnerabilities[workload.href].instances)
          .flat()
          .map((vulnerability, idx) => ({
            key: idx,
            ...vulnerability,
            traffic: traffic[[vulnerability.port, vulnerability.protocol].join(',')]?.policyDecision ?? 'none',
          }))
      : [];
  },
);

export const getVulnerabilitiesRows = createSelector(
  [getVulnerabilitiesData],
  vulnerabilitiesData => vulnerabilitiesData,
);

export const getTotalVEScore = createSelector(
  [getVulnerabilities, getWorkloadInstance],
  (vulnerabilities, workload) => {
    return {
      current: roundNumber(vulnerabilities[workload.href]?.aggregatedValues?.currentVulnerabilityExposureScore ?? null),
      visibility_only: roundNumber(
        vulnerabilities[workload.href]?.aggregatedValues?.visibilityVulnerabilityExposureScore ?? null,
      ),
      full: roundNumber(vulnerabilities[workload.href]?.aggregatedValues?.fullVulnerabilityExposureScore ?? null),
      selective: roundNumber(
        vulnerabilities[workload.href]?.aggregatedValues?.selectiveVulnerabilityExposureScore ?? null,
      ),
    };
  },
);

export const vulnerabilitiesGridSettings = createSelector(
  [getVulnerabilitiesEnforcementToggle, getTotalVEScore, areVulnerabilitiesEnabled, isUserWorkloadManager],
  (showFullEnforcement, totalVEScore, vulnerabilitiesAreEnabled, isWorkloadManager) => {
    return gridSettings({showFullEnforcement, totalVEScore, vulnerabilitiesAreEnabled, isWorkloadManager});
  },
);

const getVulnerabilitiesGrid = state =>
  getGridSelector(state, {
    settings: vulnerabilitiesGridSettings,
    rows: getVulnerabilitiesRows,
  });

export const getVulnerabilitiesPage = createSelector(
  [getWorkloadInstance, getVulnerabilitiesGrid, getTotalVEScore, getVulnerabilitiesEnforcementToggle],
  (workload, vulnerabilitiesgrid, totalVEScore, showFullEnforcement) => ({
    workload,
    vulnerabilitiesgrid,
    totalVEScore,
    showFullEnforcement,
  }),
);
