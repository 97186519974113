/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from '@illumio-shared/utils/intl';
import {createSelector} from 'reselect';
import createCachedSelector from 're-reselect';
import {
  getRouteName,
  areVulnerabilitiesEnabled,
  getRouteParams,
  isEnhancedDataCollectionEnabled,
  isRansomwareReadinessEnabled,
} from 'containers/App/AppState';
import {webStorageUtils} from '@illumio-shared/utils';
import {isSuperclusterMember, getOrgId} from 'containers/User/UserState';
import {getLocalClusterBasic} from 'containers/Health/HealthState';
import {getPolicyStateContent, getWorkloadHealthStatus} from '../WorkloadUtils';
import {getGridSelector} from 'components/Grid/GridSelectors';
import {categories, resourceType, gridSettings} from './WorkloadListConfig';
import {getPairingProfiles} from 'containers/PairingProfile/List/PairingProfileListState';
import {getPairingProfileInstance, getPairingKey} from 'containers/PairingProfile/Item/PairingProfileItemState';
import {getAllResourcesObject} from 'containers/Selector/SelectorUtils';
import {getMappedLabelsForGrid} from 'components/Grid/GridUtils';
import {getViewWorkloadsWithTraffic} from 'containers/IlluminationMap/Panels/MapPanelState';
import {getShowAllMembers} from 'containers/IlluminationMap/Graph/MapGraphState';
import {getLabelsColumn} from 'containers/Label/LabelConfig';

export default {
  list(state = [], action) {
    switch (action.type) {
      case 'WORKLOADS_GET_LIST':
        return action.data.list;
      default:
        return state;
    }
  },

  count(state = {}, action) {
    switch (action.type) {
      case 'WORKLOADS_GET_LIST':
        return action.data.count;
      default:
        return state;
    }
  },

  ignoreScope(state = false, action) {
    switch (action.type) {
      case 'WORKLOADS_GET_LIST':
        return action.data.ignoreScope;
      default:
        return state;
    }
  },

  map(state = {}, action) {
    switch (action.type) {
      case 'WORKLOADS_GET_LIST': {
        const result = {...state};

        for (const workload of action.data.list) {
          result[workload.href] = workload;
        }

        return result;
      }
      case 'WORKLOADS_GET_INSTANCE':
        if (action.data && !_.isEqual(action.data, state[action.data.href])) {
          return {...state, [action.data.href]: action.data};
        }

        return state;
      default:
        return state;
    }
  },
  workloadEnforcementToggle(state = webStorageUtils.getItem('workloadsEnforcementToggle') ?? true, action) {
    switch (action.type) {
      case 'WORKLOADS_SET_ENFORCEMENT_TOGGLE':
        webStorageUtils.setItem('workloadsEnforcementToggle', action.data);

        return action.data;
      default:
        return state;
    }
  },
};

export const getWorkloads = state => state.workload.list;
export const getWorkloadsHrefMap = state => state.workload.map;
export const getWorkloadsCount = state => state.workload.count;
export const getWorkloadsIgnoreScope = state => state.workload.ignoreScope;
export const getWorkloadsEnforcementToggle = state => state.workload.workloadEnforcementToggle;
const getOSFamilies = state => state.workload.osFamilies;

const getWorkloadsRows = createSelector(
  [getWorkloads, isSuperclusterMember, getLocalClusterBasic, isEnhancedDataCollectionEnabled],
  (workload, userIsSuperclusterMember, localCluster = {}, hasEnhancedDataCollection) =>
    workload.map(item => {
      const ven = item.agent;
      const online = item.online;
      const venConfig = ven && ven.config;
      const venStatus = ven && ven.status;
      const creator = _.get(item, 'created_by.href');
      let pairingType = 'none';

      const {enforcement_mode = '', visibility_level = '', log_traffic = ''} = item;

      if (creator && venStatus) {
        if (creator.includes('agents')) {
          pairingType = 'pairingKey';
        } else if (creator.includes('users')) {
          pairingType = 'kerberosOrPKI';
        }
      }

      const healthStatus = getWorkloadHealthStatus({ven, localCluster, userIsSuperclusterMember, online});

      return {
        pairingType,
        healthStatus,
        key: item.href,
        unmanaged: !venStatus,
        // item.caps.length === 0 "read only"
        selectable: Array.isArray(item.caps) && item.caps.length > 0,
        staticPolicy: venConfig && venConfig.security_policy_update_mode === 'static',
        policyState: getPolicyStateContent(enforcement_mode, visibility_level, log_traffic).key,
        enforcement: enforcement_mode,
        visibility: visibility_level,
        hasEnhancedDataCollection,
        data: {
          ...item,
          enforcement_mode,
          visibility_level,
          log_traffic,
          labelsMap: getMappedLabelsForGrid(item.labels),
        },
      };
    }),
);

export const getCategories = createSelector(
  [categories, getWorkloadsCount, getWorkloadsIgnoreScope],
  (categoriesArr, count, ignoreScope) => {
    let categories = categoriesArr;

    if (!ignoreScope) {
      // PCE category exists in supercluster environment if ignoreScope is false
      const pceCategoryIndex = categories.findIndex(({name}) => name === intl('Common.PCE'));

      if (pceCategoryIndex !== -1) {
        // set hidden false to show this category in filters
        categories[pceCategoryIndex] = {...categories[pceCategoryIndex], hidden: false};
      }
    }

    if (!count.staticMode) {
      // If none of the workloads have static policy applied then
      // remove security settings static policy related filters
      categories = categories.reduce((result, category) => {
        if (category.id === 'security_policy_received_at' || category.id === 'security_policy_update_mode') {
          return result;
        }

        if (category.id === 'policy_health') {
          const statics = category.resources.policy_health.statics;

          result.push({
            ...category,
            resources: {
              policy_health: {statics: statics.filter(policyHealth => policyHealth.value !== intl('Common.Staged'))},
            },
          });

          return result;
        }

        result.push(category);

        return result;
      }, []);
    }

    return categories;
  },
);

export const getFilterMap = createSelector([getCategories], categories => getAllResourcesObject(categories));

export const getWorkloadListGridSettings = createSelector(
  [getLabelsColumn, isRansomwareReadinessEnabled, getWorkloadsEnforcementToggle, getRouteParams],
  (labelsColumn, isRansomwareReadinessEnabled, isFullEnforcement, routeParams) => {
    const {display, mapMode} = routeParams;
    const showPotentialVEScore = display !== 'map' || (display === 'map' && mapMode === 'vulnerability');

    return gridSettings({labelsColumn, isRansomwareReadinessEnabled, showPotentialVEScore, isFullEnforcement});
  },
);

export const getGridSettings = createCachedSelector(
  [areVulnerabilitiesEnabled, getWorkloadListGridSettings, (_, props) => props?.gridId],
  (vulnerabilitiesAreEnabled, gridSettings, gridId) => {
    const columns = {...gridSettings.columns};

    // Fields to disable in CrowdStrike + Discovered Workloads and hide in Antman
    columns.currentVEScore.disabled = !vulnerabilitiesAreEnabled;
    columns.potentialVEScore.disabled = !vulnerabilitiesAreEnabled;
    columns.sync.disabled = false;
    columns.hostname.disabled = false;
    columns.paired.disabled = false;
    columns.visibility.disabled = false;

    const sort = vulnerabilitiesAreEnabled ? '-currentVEScore' : gridSettings.sort;

    const newGridSettings = {...gridSettings, columns, sort};

    if (gridId) {
      // grid config key in kvPairs consists of gridId and routeName.
      // In case of groupDashboard there are two workload lists and both uses the same ID: 1. Discovered workloads 2. Group workloads
      // Because of this grid config of one grid overwrites another one.
      // So, we need to pass a different gridId in this case to have unique kvPairs entries
      newGridSettings.id = gridId;
    }

    return newGridSettings;
  },
)((state, props) => props?.gridId ?? getWorkloadListGridSettings(state).id);

export const getGrid = (state, props) =>
  getGridSelector(state, {
    settings: state => getGridSettings(state, props),
    rows: getWorkloadsRows,
    filterMap: getFilterMap,
  });

export const getPairingProfilePairAccessList = createSelector(getPairingProfiles, pairingProfileList =>
  pairingProfileList.filter(
    pairingProfile =>
      // Don't allow pairing to profile that has been disabled
      Array.isArray(pairingProfile.caps) &&
      pairingProfile.caps.includes('generate_pairing_key') &&
      pairingProfile.enabled,
  ),
);

export const getPairingProfilePairs = createSelector(
  [getPairingProfileInstance, getOSFamilies, getPairingKey],
  (pairingProfile, workloadOsFamilies, activationCode) => ({
    // Activation code used for Pairing Profile
    activationCode,
    // Specific pairing profile instance
    pairingProfile,
    // Workload OS Families
    workloadOsFamilies,
  }),
);

export const getPageType = createSelector([getRouteParams, getRouteName], (params, routeName) => {
  const isVenListPage = __ANTMAN__ && routeName === 'app.workloads.vens.list';
  const isServersPage = __ANTMAN__ && params?.type === 'server';
  const isEndpointsPage = __ANTMAN__ && params?.type === 'endpoint';

  return {
    isVenListPage,
    isServersPage,
    isEndpointsPage,
    isWorkloadsPage: !__ANTMAN__ || (!isServersPage && !isEndpointsPage && !isVenListPage),
  };
});

export const getWorkloadsPage = createSelector(
  [
    getGrid,
    getWorkloadsCount,
    getCategories,
    getOrgId,
    getRouteParams,
    getViewWorkloadsWithTraffic,
    getPairingProfilePairs,
    getPageType,
    getShowAllMembers,
    getWorkloadsEnforcementToggle,
    areVulnerabilitiesEnabled,
  ],
  (
    grid,
    count,
    categories,
    orgId,
    params,
    viewWorkloadsWithTraffic,
    pairingProfilePairs,
    {isVenListPage, isServersPage, isEndpointsPage, isWorkloadsPage},
    showAllMembers,
    isFullEnforcement,
    vulnerabilitiesAreEnabled,
  ) => {
    let pageTitle;

    if (__ANTMAN__) {
      pageTitle = isServersPage
        ? intl('Common.Servers')
        : isEndpointsPage
        ? intl('Antman.Endpoints.Endpoints')
        : intl('Workloads.All');
    } else {
      pageTitle = intl('Common.Workloads');
    }

    return {
      grid,
      count,
      resourceType,
      orgId,
      params,
      viewWorkloadsWithTraffic,
      pageTitle,
      categories,
      isVenListPage,
      isWorkloadsPage,
      isServersPage,
      isEndpointsPage,
      showAllMembers,
      isFullEnforcement,
      vulnerabilitiesAreEnabled,
    };
  },
);
